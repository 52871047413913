<template>
  <div class="ibox" ref="listInvoices">
    <div class="ibox-title">
      <h4>{{ $t('invoice.list-title') }}</h4>
      <div class="ibox-tools">
        <a class="collapse-link">
            <i class="fa fa-chevron-up"></i>
        </a>
      </div>
    </div>
    <div :class="'ibox-content p-md' + (listInvoicesLoading ? ' sk-loading' : '')">

      <div v-if="listInvoicesLoading" class="sk-spinner sk-spinner-double-bounce">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
      </div>

      <b-table 
          outlined striped
          :items="invoicesProvider"
          :fields="invoiceFields"
          ref="listInvoices"
          show-empty
          :empty-text="$t('invoice.no-invoice')">
        <template v-slot:cell(select)="row">
          <p-check :id="'invoiceSelect_'+row.item._id" class="p-default p-curve p-primary" name="listInvoicesSelected" color="success" :value="row.item._id" v-model="listInvoicesSelected"></p-check>
        </template>
        <template v-slot:cell(options)="row">
          <a :href="getDownloadURL(row.item)" target="_blank"><i class="fa fa-cloud-download"></i></a>
        </template>
      </b-table>

      <div v-if="listInvoicesSelected.length > 0">
        Several invoices selected.
      </div>

    </div>
  </div>
</template>

<style scoped>
  td div.pretty {
    margin:0;
  }
</style>


<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import * as api from '@fwk-client/utils/api';
import { languagesTypes } from '@fwk-client/store/types';
import { enableIbox, disableIbox} from '../../../../theme/inspinia-jquery'; 

@Component({
  components: {}
})
export default class ListInvoices extends Vue {

  @Prop({
    type: String,
    required: true
  }) readonly companyId!: string

  @Prop({
      type: Boolean,
      required: false,
      default: true
    }) readonly showCompanyColumn!: boolean
  
  
  listInvoicesLoading = false;

  listInvoicesSelected = [];

  invoiceFields = [
      /*{
        key: "select",
        label: ""
      },*/
      {
        key: "formattedCreationDateTime",
        label: ""
      },
      {
        key: "number",
        label: ""
      },
      {
        key: "company",
        formatter: (value:any, key:any, item:any) => {
          return item.company.name;
        },
        class: (this.showCompanyColumn ? "" : "hidden"),
        label:""
      },
      {
        key: "formattedTotalAmountWithoutTax",
        label: ""
      },
      {
        key: "options",
        label: ""
      }
    ];

  mounted() {
    this.updateTableLabels();

    enableIbox(this.$refs.listInvoices as HTMLElement);
  }

  updateTableLabels() {
    this.invoiceFields[0].label = this.$t('invoice.headers.creationDateTime') as string;
    this.invoiceFields[1].label = this.$t('invoice.headers.number') as string;
    this.invoiceFields[2].label = this.$t('invoice.headers.company') as string;
    this.invoiceFields[3].label = this.$t('invoice.headers.totalAmountWithoutTax') as string;
    this.invoiceFields[4].label = this.$t('invoice.headers.options') as string; 
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.updateTableLabels();
  }

  beforeDestroy() {
    disableIbox(this.$refs.listInvoices as HTMLElement);
  }

  invoicesProvider(ctx:any) {
    var options:api.ApiVueOptions =  {
      app: this
    }

    this.listInvoicesLoading = true;

    return api.getAPI('/api/invoices/company/'+this.companyId+'/list', options).then((response:any) => {
      this.listInvoicesLoading = false;
      if(response.invoices) {  
        return response.invoices;
      }
      else {
        return [];
      }
    })
  }

  get currentLanguageCode() {
    return this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE]
  }

  getDownloadURL(invoice:any) {
    return '/'+ this.currentLanguageCode +'/api/invoices/'+invoice._id+'/download';
  }
  
}
</script>